import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";

// markup
export default function ReservedPage() {
  return (
    <Layout>
      <Helmet title="Table reservations and click-and-collect ordering with VIP Dine" />
      <section className="section hero reserved-hero sub-page">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>
                Subscription Plans for{" "}
                <span className="product-name yellow">Reserved</span> and{" "}
                <span className="product-name yellow">Ordered</span>
              </h1>
            </div>
          </div>
        </div>

        <div className="overlay"></div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row stretch">
            <div className="col">
              <h2>
                <span className="product-name">Reserved</span> - £199 a year
              </h2>
              <p>Add online table reservations to your website.</p>
              <p>
                At a really low cost, you reap benefits of increased covers
                while showcasing your restaurant's professional webpages with a
                reservations feature in it.
              </p>
              <p>Take your table reservation volume to the next level.</p>
            </div>

            <div className="col">
              <div className="price-summary">
                <div className="product-includes">
                  <h3>Table Reservations Widget</h3>
                  <p>Unlimited Integration</p>
                </div>

                <div className="product-includes">
                  <h3>Optional Deposit Payments</h3>
                  <p>
                    Gateway Charges = 1.4% + 20p. Transaction Fee 1.4% + 20p
                  </p>
                </div>

                <div className="product-includes">
                  <h3>VIP Portal access</h3>
                  <p>Availability and Reservations Management</p>
                </div>

                <div className="product-includes">
                  <h3>SMS confirmation</h3>
                  <p>
                    Free upto 500 reservations. 20p per reservation after that
                    (Optional)
                  </p>
                </div>

                <div className="product-includes">
                  <h3>Email Confirmation</h3>
                  <p>Unlimited</p>
                </div>

                <Link to="/register/" className="btn btn-primary">
                  Subscribe to Reserved
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container hr">
        <div class="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row stretch">
            <div className="col">
              <h2>
                <span className="product-name">Ordered</span> - £120 a year
              </h2>
              <p>Add click-and-collect ordering to your website.</p>
              <p>
                Start taking online orders in a matter of minutes. Let your
                customers use the easiest way to order and pay giving you that
                potential to sell more at really affordable costs.
              </p>
              <p>Start selling online easily and securely today.</p>
            </div>

            <div className="col">
              <div className="price-summary">
                <div className="product-includes">
                  <h3>Food Click-and-collect Widget</h3>
                  <p>Unlimited Integration</p>
                </div>

                <div className="product-includes">
                  <h3>Food Cart and Checkout</h3>
                  <p>
                    Gateway Charges = 1.4% + 20p. Transaction Fee 1.4% + 20p
                  </p>
                </div>

                <div className="product-includes">
                  <h3>VIP Portal access</h3>
                  <p>Availability and Reservations Management</p>
                </div>

                <div className="product-includes">
                  <h3>SMS confirmation</h3>
                  <p>
                    Free upto 500 reservations. 20p per reservation after that
                    (Optional)
                  </p>
                </div>

                <div className="product-includes">
                  <h3>Email Confirmation</h3>
                  <p>Unlimited</p>
                </div>

                <Link to="/register/" className="btn btn-primary">
                  Subscribe to Ordered
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container hr">
        <div class="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row stretch">
            <div className="col">
              <h2>
                <span className="product-name">VIP Bundle</span> - £299 a year
              </h2>
              <p>
                Add online table reservations and click-and-collect ordering to
                your website.{" "}
              </p>
              <p>
                Get both <span className="product-name">Reserved</span> and{" "}
                <span className="product-name">Ordered</span> for a discounted
                price with our <strong>best value</strong> VIP bundle.
              </p>
            </div>

            <div className="col">
              <div className="price-summary">
                <div className="product-includes">
                  <h3>Table Reservation and Food Click-and-collect Widgets</h3>
                  <p>Unlimited Integration</p>
                </div>

                <div className="product-includes">
                  <h3>VIP Portal access</h3>
                  <p>Availability and Reservations Management</p>
                </div>

                <div className="product-includes">
                  <h3>SMS confirmation</h3>
                  <p>
                    Free upto 500 reservations. 20p per reservation after that
                    (Optional)
                  </p>
                </div>

                <div className="product-includes">
                  <h3>Email Confirmation</h3>
                  <p>Unlimited</p>
                </div>

                <div className="product-includes">
                  <h3>Optional Reservation Deposit Payments</h3>
                  <p>
                    Gateway Charges = 1.4% + 20p. Transaction Fee 1.4% + 20p
                  </p>
                </div>

                <div className="product-includes">
                  <h3>Food Cart and Checkout</h3>
                  <p>
                    Gateway Charges = 1.4% + 20p. Transaction Fee 1.4% + 20p
                  </p>
                </div>

                <Link to="/register/" className="btn btn-primary">
                  Subscribe to the VIP Bundle
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container hr">
        <div class="hr"></div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Frequently Asked Questions</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row stretch">
            <div className="col">
              <h3>We don't have a website, can you help?</h3>
              <p>
                Yes, we can setup a basic website for your restaurant at an
                additional cost. You have few options to acheive that which
                includes a fully hosted solution. Please contact us for more
                information.
              </p>
            </div>
            <div className="col">
              <h3>How can I get the widget working on my website?</h3>
              <p>
                Once your subscription begins, you can grab the widget code from
                the VIP portal. Your web developer should easily be able to
                integrate the widget to your website.
              </p>
            </div>
          </div>
          <div className="row stretch">
            <div className="col">
              <h3>Can I use my restaurant widget at multiple web pages?</h3>
              <p>
                Yes, once your subscription begins, you can use your
                restaurant's widget at as many location as you desire including
                your website and social media pages.
              </p>
            </div>
            <div className="col">
              <h3>
                My restaurant has multiple branches do I need multiple
                subscriptions?
              </h3>
              <p>
                Yes, a subscription is valid only for one restaurant(branch).
                You will need a subscription for each of your branches. This
                allows you to configure them individually and manage
                reservations independently.
              </p>
            </div>
          </div>
          <div className="row stretch">
            <div className="col">
              <h3>
                Can we use our customers contacts to send marketing content?
              </h3>
              <p>
                Yes, this is subject to the customer agreeing to that in
                advance. Customers can opt-in for your restaurant's marketing
                and newsletter updates through the widget. Your customers data
                is only yours and you are therefore responsible for abiding by
                data protection laws and privacy policies.
              </p>
            </div>
            <div className="col">
              <h3>Can I cancel at anytime?</h3>
              <p>
                You can cancel anytime and that stops your widget from
                functioning, However your subscription will be terminated only
                at the end of the subscription period and no refunds will be
                given.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
